import { default as _91_46_46_46all_93TWnNQWGwMCMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/[...all].vue?macro=true";
import { default as addressl8tiP0VK7zMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/address.vue?macro=true";
import { default as indexA435WCKeCKMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/index.vue?macro=true";
import { default as order9QAyd7wFEBMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/order.vue?macro=true";
import { default as paymentEHjlJapdkwMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/payment.vue?macro=true";
import { default as profilefRM8wYbeV8Meta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/profile.vue?macro=true";
import { default as recoverED4Knl7TsUMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/account/recover.vue?macro=true";
import { default as cartQ6K9Ug9am6Meta } from "/home/runner/work/shop_frontend/shop_frontend/pages/cart.vue?macro=true";
import { default as indexe0XI2TruZDMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/checkout/index.vue?macro=true";
import { default as _91id_93GR6NKV3OxaMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/checkout/summary/[id].vue?macro=true";
import { default as errord2KkTMVWTFMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/error.vue?macro=true";
import { default as _91slug_93liT5t97ACqMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/Filialen/Standorte/[slug].vue?macro=true";
import { default as _91slug_93pH9xEIMrVlMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/jobs/[slug].vue?macro=true";
import { default as _91slug_93OwDSgVyrfoMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/katalog/[slug].vue?macro=true";
import { default as loginj3tjCIi1xVMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/login.vue?macro=true";
import { default as maintenance32GlRVs0LkMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/maintenance.vue?macro=true";
import { default as newsletter_45subscribeVaYPV8ciMpMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/newsletter-subscribe.vue?macro=true";
import { default as registerGK7XXh2ffeMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/register.vue?macro=true";
import { default as reset_45passwordmzhdeYlYr7Meta } from "/home/runner/work/shop_frontend/shop_frontend/pages/reset-password.vue?macro=true";
import { default as searchgeC6AQXx9WMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/search.vue?macro=true";
import { default as wishlistMckPbajytYMeta } from "/home/runner/work/shop_frontend/shop_frontend/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93TWnNQWGwMCMeta?.name ?? "all",
    path: _91_46_46_46all_93TWnNQWGwMCMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93TWnNQWGwMCMeta || {},
    alias: _91_46_46_46all_93TWnNQWGwMCMeta?.alias || [],
    redirect: _91_46_46_46all_93TWnNQWGwMCMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addressl8tiP0VK7zMeta?.name ?? "account-address",
    path: addressl8tiP0VK7zMeta?.path ?? "/account/address",
    meta: addressl8tiP0VK7zMeta || {},
    alias: addressl8tiP0VK7zMeta?.alias || [],
    redirect: addressl8tiP0VK7zMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: indexA435WCKeCKMeta?.name ?? "account",
    path: indexA435WCKeCKMeta?.path ?? "/account",
    meta: indexA435WCKeCKMeta || {},
    alias: indexA435WCKeCKMeta?.alias || [],
    redirect: indexA435WCKeCKMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: order9QAyd7wFEBMeta?.name ?? "account-order",
    path: order9QAyd7wFEBMeta?.path ?? "/account/order",
    meta: order9QAyd7wFEBMeta || {},
    alias: order9QAyd7wFEBMeta?.alias || [],
    redirect: order9QAyd7wFEBMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentEHjlJapdkwMeta?.name ?? "account-payment",
    path: paymentEHjlJapdkwMeta?.path ?? "/account/payment",
    meta: paymentEHjlJapdkwMeta || {},
    alias: paymentEHjlJapdkwMeta?.alias || [],
    redirect: paymentEHjlJapdkwMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profilefRM8wYbeV8Meta?.name ?? "account-profile",
    path: profilefRM8wYbeV8Meta?.path ?? "/account/profile",
    meta: profilefRM8wYbeV8Meta || {},
    alias: profilefRM8wYbeV8Meta?.alias || [],
    redirect: profilefRM8wYbeV8Meta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: recoverED4Knl7TsUMeta?.name ?? "account-recover",
    path: recoverED4Knl7TsUMeta?.path ?? "/account/recover",
    meta: recoverED4Knl7TsUMeta || {},
    alias: recoverED4Knl7TsUMeta?.alias || [],
    redirect: recoverED4Knl7TsUMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: cartQ6K9Ug9am6Meta?.name ?? "cart",
    path: cartQ6K9Ug9am6Meta?.path ?? "/cart",
    meta: cartQ6K9Ug9am6Meta || {},
    alias: cartQ6K9Ug9am6Meta?.alias || [],
    redirect: cartQ6K9Ug9am6Meta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexe0XI2TruZDMeta?.name ?? "checkout",
    path: indexe0XI2TruZDMeta?.path ?? "/checkout",
    meta: indexe0XI2TruZDMeta || {},
    alias: indexe0XI2TruZDMeta?.alias || [],
    redirect: indexe0XI2TruZDMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GR6NKV3OxaMeta?.name ?? "checkout-summary-id",
    path: _91id_93GR6NKV3OxaMeta?.path ?? "/checkout/summary/:id()",
    meta: _91id_93GR6NKV3OxaMeta || {},
    alias: _91id_93GR6NKV3OxaMeta?.alias || [],
    redirect: _91id_93GR6NKV3OxaMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/checkout/summary/[id].vue").then(m => m.default || m)
  },
  {
    name: errord2KkTMVWTFMeta?.name ?? "error",
    path: errord2KkTMVWTFMeta?.path ?? "/error",
    meta: errord2KkTMVWTFMeta || {},
    alias: errord2KkTMVWTFMeta?.alias || [],
    redirect: errord2KkTMVWTFMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93liT5t97ACqMeta?.name ?? "Filialen-Standorte-slug",
    path: _91slug_93liT5t97ACqMeta?.path ?? "/Filialen/Standorte/:slug()",
    meta: _91slug_93liT5t97ACqMeta || {},
    alias: _91slug_93liT5t97ACqMeta?.alias || [],
    redirect: _91slug_93liT5t97ACqMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/Filialen/Standorte/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pH9xEIMrVlMeta?.name ?? "jobs-slug",
    path: _91slug_93pH9xEIMrVlMeta?.path ?? "/jobs/:slug()",
    meta: _91slug_93pH9xEIMrVlMeta || {},
    alias: _91slug_93pH9xEIMrVlMeta?.alias || [],
    redirect: _91slug_93pH9xEIMrVlMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OwDSgVyrfoMeta?.name ?? "katalog-slug",
    path: _91slug_93OwDSgVyrfoMeta?.path ?? "/katalog/:slug()",
    meta: _91slug_93OwDSgVyrfoMeta || {},
    alias: _91slug_93OwDSgVyrfoMeta?.alias || [],
    redirect: _91slug_93OwDSgVyrfoMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/katalog/[slug].vue").then(m => m.default || m)
  },
  {
    name: loginj3tjCIi1xVMeta?.name ?? "login",
    path: loginj3tjCIi1xVMeta?.path ?? "/login",
    meta: loginj3tjCIi1xVMeta || {},
    alias: loginj3tjCIi1xVMeta?.alias || [],
    redirect: loginj3tjCIi1xVMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenance32GlRVs0LkMeta?.name ?? "maintenance",
    path: maintenance32GlRVs0LkMeta?.path ?? "/maintenance",
    meta: maintenance32GlRVs0LkMeta || {},
    alias: maintenance32GlRVs0LkMeta?.alias || [],
    redirect: maintenance32GlRVs0LkMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribeVaYPV8ciMpMeta?.name ?? "newsletter-subscribe",
    path: newsletter_45subscribeVaYPV8ciMpMeta?.path ?? "/newsletter-subscribe",
    meta: newsletter_45subscribeVaYPV8ciMpMeta || {},
    alias: newsletter_45subscribeVaYPV8ciMpMeta?.alias || [],
    redirect: newsletter_45subscribeVaYPV8ciMpMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: registerGK7XXh2ffeMeta?.name ?? "register",
    path: registerGK7XXh2ffeMeta?.path ?? "/register",
    meta: registerGK7XXh2ffeMeta || {},
    alias: registerGK7XXh2ffeMeta?.alias || [],
    redirect: registerGK7XXh2ffeMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordmzhdeYlYr7Meta?.name ?? "reset-password",
    path: reset_45passwordmzhdeYlYr7Meta?.path ?? "/reset-password",
    meta: reset_45passwordmzhdeYlYr7Meta || {},
    alias: reset_45passwordmzhdeYlYr7Meta?.alias || [],
    redirect: reset_45passwordmzhdeYlYr7Meta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchgeC6AQXx9WMeta?.name ?? "search",
    path: searchgeC6AQXx9WMeta?.path ?? "/search",
    meta: searchgeC6AQXx9WMeta || {},
    alias: searchgeC6AQXx9WMeta?.alias || [],
    redirect: searchgeC6AQXx9WMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: wishlistMckPbajytYMeta?.name ?? "wishlist",
    path: wishlistMckPbajytYMeta?.path ?? "/wishlist",
    meta: wishlistMckPbajytYMeta || {},
    alias: wishlistMckPbajytYMeta?.alias || [],
    redirect: wishlistMckPbajytYMeta?.redirect,
    component: () => import("/home/runner/work/shop_frontend/shop_frontend/pages/wishlist.vue").then(m => m.default || m)
  }
]