<script setup lang="ts">
import { getSessionContext } from "@shopware-pwa/api-client";
import { SessionContext } from "@shopware-pwa/types";

useHead({
  title: "Maas Natur",
  meta: [
    {
      name: "description",
      content:
        "Ökologisch sinnvolle Kleidung. Achtsamkeit gegenüber Natur, Tier und Mensch. Dafür steht Maas mit seiner Naturmode seit mehr als 35 Jahren. Unsere hochwertigen Produkte vertreiben wir über einen Webshop sowie 11 Filialen in ganz Deutschland",
    },
    { name: "msapplication-TileColor", content: "#da532c" },
    { name: "theme-color", content: "#ffffff" },
  ],
  htmlAttrs: {
    lang: "de",
  },
  link: [
    { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
    { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#2B5078" },
  ],
});

const { apiInstance } = useShopwareContext();
const sessionContextData = ref();

const route = useRoute();

try {
  sessionContextData.value = await getSessionContext(apiInstance);
} catch (e: any) {
  if (e.statusCode === 503) {
    if (route.path !== '/maintenance/') {
      navigateTo({ path: '/maintenance/', query: { errorCode: 503 }});
    }
  }
  else 
  {
    if (route.path !== '/error/') { 
      navigateTo({ path: '/error/', query: { statusCode: e.statusCode, statusMessage: e.statusMessage}});
    }
  }
  
}

// If you enable runtimeConfig.shopware.useUserContextInSSR, then you can use this code to share session between server and client.
// const { data: sessionContextData } = await useAsyncData(
//   "sessionContext",
//   async () => {
//     return await getSessionContext(apiInstance);
//   }
// );

// read the locale/lang code from accept-language header (i.e. en, en-GB or de-DE)
// and set configuration for price formatting globally
const headers = useRequestHeaders();
// Extract the first (with highest priority order) locale or lang code from accept-language header
// for example: "en-US;q=0.7,en;q=0.3" will return "en-US"
const localeFromHeader = headers?.["accept-language"]
  ?.split(",")
  ?.map(
    (languageConfig) => languageConfig.match(/^([a-z]{2}(?:-[A-Z]{2})?)/)?.[0],
  )
  .find(Boolean);

if (sessionContextData.value) {
  /**
   * Init breadcrumbs context
   */
  useBreadcrumbs();

  usePrice({
    currencyCode: sessionContextData.value?.currency?.isoCode || "",
    localeCode: localeFromHeader,
  });

  useSessionContext(sessionContextData.value as SessionContext);

  const { getWishlistProducts } = useWishlist();
  const { refreshCart } = useCart();

  useNotificationsEx();
  useAddress();

  onMounted(() => {
    refreshCart();
    getWishlistProducts();
  });
}

// Deactivate language selection => Immer deutsch um Anfragen an Backend zu reduzieren
/*
const { locale, availableLocales } = useI18n();
const router = useRouter();
const {
  getAvailableLanguages,
  getLanguageCodeFromId,
  getLanguageIdFromCode,
  changeLanguage,
  languages: storeLanguages,
} = useInternationalization();
const { languageIdChain, refreshSessionContext } = useSessionContext();

const { data: languages } = await useAsyncData("languages", async () => {
  return await getAvailableLanguages();
});

if (languages.value?.elements.length && router.currentRoute.value.name) {
  storeLanguages.value = languages.value?.elements;
  // Prefix from url
  const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
  );

  // Language set on the backend side
  const sessionLanguage = getLanguageCodeFromId(languageIdChain.value);

  // If languages are not the same, set one from prefix
  if (sessionLanguage !== prefix) {
    await changeLanguage(
      getLanguageIdFromCode(prefix ? prefix : getDefaultLocale()),
    );
    await refreshSessionContext();
  }

  locale.value = prefix ? prefix : getDefaultLocale();
  // Set prefix from CMS components
  provide("urlPrefix", prefix);
}
*/
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <CookieControl locale="de" />
</template>

<style>
</style>
