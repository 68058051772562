import redirects from "./redirects/redirects.json";

export default defineNuxtRouteMiddleware((to: any, from:any): any => {
  /**
   * check if e.g. '/Damen/Bekleidung/Shirts-Tops/' is in redirects.js
   * OR check if e.g. '/Damen/Bekleidung/Shirts-Tops/' without trailing slash is in redirects.js
   */
  if ((redirects as any)[to.path]) {
    return navigateTo((redirects as any)[to.path], { redirectCode: 301 });
  } else if((to.path.endsWith('/') && (redirects as any)[to.path.slice(0, -1)])){
    return navigateTo((redirects as any)[to.path.slice(0, -1)], { redirectCode: 301 });
  }

  /**
   * always add trailing slash
   */
  if (to.path !== '/' && !to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path + '/';
    const nextRoute = { path: nextPath, query, hash };

    return navigateTo(nextRoute, { replace: true });
  }
});