import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": true,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "id": "necessary",
        "name": {
          "de": "Shop Cookies"
        },
        "description": {
          "de": "Für die reibungslose Funktion des Shops unerlässlich Cookies. Sie werden automatisch gesetzt, um grundlegende Funktionen zu ermöglichen."
        },
        "targetCookieIds": [
          "sw-context-token",
          "sw-language-id",
          "viewport",
          "ncc_e",
          "ncc_c"
        ]
      }
    ],
    "optional": [
      {
        "id": "functional",
        "name": {
          "de": "Funktionale Cookies"
        },
        "description": {
          "de": "Zur Verbesserung der Funktionalität, nutzen wir externe Services wie Karten und Reservierungsservices."
        },
        "targetCookieIds": []
      },
      {
        "id": "marketing",
        "name": {
          "de": "Marketing Cookies"
        },
        "description": {
          "de": "Zur Verbesserung des Nutzererlebnis erheben wir Daten zu Analysezwecken oder stellen Ihnen personalisierte Inhalte zur Verfügung."
        },
        "targetCookieIds": [
          "ga:user"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": false,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": false,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "de",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "de": {
      "accept": "Akzeptieren",
      "acceptAll": "Akzeptieren",
      "bannerDescription": "Unsere Website verwendet Cookies um unseren Kunden die bestmögliche Erfahrung in unserem Shop bieten zu können. Weitere Informationen finden Sie in unserer Datenschutzerklärung.",
      "bannerTitle": "Cookies",
      "close": "✕",
      "cookiesFunctional": "Funktionale Cookies",
      "cookiesNecessary": "Notwendige Cookies",
      "cookiesOptional": "Optionale Cookies",
      "decline": "Ablehnen",
      "declineAll": "Ablehnen",
      "here": "hier",
      "iframeBlocked": "Bitte funktionale Cookies aktivieren:",
      "manageCookies": "Anpassen",
      "save": "Speichern",
      "settingsUnsaved": "Bitte speichern Sie Ihre Einstellungen"
    }
  }
} as ModuleOptions