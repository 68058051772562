import revive_payload_client_Zg8gIqUklN from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eetaMiKX4g from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tNgTDoaPns from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OvQAEuO2bY from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_JUrEDyexMR from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/shop_frontend/shop_frontend/.nuxt/components.plugin.mjs";
import prefetch_client_MiRLbswdaU from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_VfNkkwGPj6 from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt-gtag@2.0.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_MhZCtcKsJZ from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@6.5.3/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_rfGcj8J4is from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt-viewport@2.1.4_vue@3.4.25/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import shopware_plugin_txRT4f0fRY from "/home/runner/work/shop_frontend/shop_frontend/.nuxt/runtime/shopware.plugin.mjs";
import composition_KGwwdYNP1Q from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_@vercel+kv@1.0.1_vue@3.4.25/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_PkA4GnC0DE from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_@vercel+kv@1.0.1_vue@3.4.25/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/home/runner/work/shop_frontend/shop_frontend/.nuxt/unocss.mjs";
import chunk_reload_client_JbKtEd6gJo from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.58.9_@vercel+kv@1.0.1_axios@1.6.8_eslint@8.57.0_floating-vue@5.2._e6crmtq3ifxj6ws76frcvxi4we/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_GGK9v2l2F9 from "/home/runner/work/shop_frontend/shop_frontend/node_modules/.pnpm/@vueuse+nuxt@10.9.0_nuxt@3.11.2_vue@3.4.25/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import sentry_client_shVUlIjFLk from "/home/runner/work/shop_frontend/shop_frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_Zg8gIqUklN,
  unhead_eetaMiKX4g,
  router_tNgTDoaPns,
  payload_client_OvQAEuO2bY,
  check_outdated_build_client_JUrEDyexMR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MiRLbswdaU,
  plugin_client_VfNkkwGPj6,
  plugin_MhZCtcKsJZ,
  plugin_client_rfGcj8J4is,
  shopware_plugin_txRT4f0fRY,
  composition_KGwwdYNP1Q,
  i18n_PkA4GnC0DE,
  unocss_MzCDxu9LMj,
  chunk_reload_client_JbKtEd6gJo,
  ssr_plugin_GGK9v2l2F9,
  sentry_client_shVUlIjFLk
]