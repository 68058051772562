import * as Sentry from "@sentry/vue";
import { defineNuxtPlugin, useRuntimeConfig } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig();

  if (!config.public.SENTRY_DSN) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.SENTRY_DSN,
    enabled: config.public.SENTRY_ENABLED,
    environment: config.public.SENTRY_ENVIRONMENT,
    release: config.public.SENTRY_RELEASE,
    debug: config.public.SENTRY_ENVIRONMENT !== "production",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    sampleRate: 1,
    tracesSampleRate: 0.05,

    /*
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
        tracePropagationTargets: [config.public.SENTRY_TRACE_PROPAGATION_TARGET],
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    hooks: ["activate", "create", "destroy", "mount", "update"],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    */
  });

  /*
  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
  */
});
